<template>
  <div>
    <v-card flat>
      <v-card-title>
        <label class="primary--text px-5 font-weight-bold info-page-title ma-auto">{{ $t(`label.tnc`) }}</label>
      </v-card-title>
    </v-card>

    <v-card class="info-page-card text-align-justify">
      <v-expansion-panels :accordion="false" v-model="panel">
        <v-expansion-panel v-for="tnc in tncContent" :key="tnc.title">
          <v-expansion-panel-header class="font-weight-bold text-uppercase">{{ tnc.title }}</v-expansion-panel-header>
          <v-expansion-panel-content class="py-4 info-expension-panel-content">
            <div v-for="content in tnc.content" :key="content.title">
              <p class="text-capitalize font-weight-bold  mb-1" v-html="content.title"></p>
              <p v-html="content.desc"></p>
              <div v-if="content.content && content.content.length > 0" class="ml-6">
                <div v-for="content1 in content.content" :key="content1.title">
                  <p class="text-capitalize font-weight-bold  mb-1" v-html="content1.title"></p>
                  <p v-html="content1.desc"></p>
                  <div v-if="content1.content && content1.content.length > 0" class="ml-8">
                    <div v-for="content2 in content1.content" :key="content2.title">
                      <p class="text-capitalize font-weight-bold  mb-1" v-html="content2.title"></p>
                      <p v-html="content2.desc"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>
<script>
import { locale, uiHelper } from "@/util";
export default {
  name: 'tnc',
  data: () => ({
    panel: 0,
    tncContent: [
      {
        title: locale.getMessage(`tnc.tnc_head`),
        content: [
          { 
            title: locale.getMessage(`tnc.tnc_1_title`),
            content: [
              { desc: locale.getMessage(`tnc.tnc_1_a`) },
              { desc: locale.getMessage(`tnc.tnc_1_b`) },
              { desc: locale.getMessage(`tnc.tnc_1_c`) },
              { desc: locale.getMessage(`tnc.tnc_1_d`) },
              { desc: locale.getMessage(`tnc.tnc_1_e`) },
              { desc: locale.getMessage(`tnc.tnc_1_f`) },
            ]
          },
          { 
            title: locale.getMessage(`tnc.tnc_2_title`),
            content: [
              {
                desc: locale.getMessage(`tnc.tnc_2_a`),
                content: [
                  { desc: locale.getMessage(`tnc.tnc_2_a_i`) },
                  { desc: locale.getMessage(`tnc.tnc_2_a_ii`) },
                ]
              },
              {
                desc: locale.getMessage(`tnc.tnc_2_b`),
                content: [
                  { desc: locale.getMessage(`tnc.tnc_2_b_i`) },
                  { desc: locale.getMessage(`tnc.tnc_2_b_ii`) }
                ]
              },
              {
                desc: locale.getMessage(`tnc.tnc_2_c`),
                content: [
                  { desc: locale.getMessage(`tnc.tnc_2_c_i`) },
                  { desc: locale.getMessage(`tnc.tnc_2_c_ii`) },
                  { desc: locale.getMessage(`tnc.tnc_2_c_iii`) }
                ]
              }
            ]
          },
          { 
            title: locale.getMessage(`tnc.tnc_3_title`),
            content: [
              { desc: locale.getMessage(`tnc.tnc_3_a`) },
              { desc: locale.getMessage(`tnc.tnc_3_b`) },
            ],
          },
          { 
            title: locale.getMessage(`tnc.tnc_4_title`),
            content: [
              { desc: locale.getMessage(`tnc.tnc_4_a`) },
              { desc: locale.getMessage(`tnc.tnc_4_b`) },
              { desc: locale.getMessage(`tnc.tnc_4_c`) },
            ],
          },
          { 
            title: locale.getMessage(`tnc.tnc_5_title`),
            desc: locale.getMessage(`tnc.tnc_5_desc`),
            content: [
              { desc: locale.getMessage(`tnc.tnc_5_i`) },
              { desc: locale.getMessage(`tnc.tnc_5_ii`) },
              { desc: locale.getMessage(`tnc.tnc_5_iii`) },
              { desc: locale.getMessage(`tnc.tnc_5_iv`) },
              { desc: locale.getMessage(`tnc.tnc_5_v`) },
              { desc: locale.getMessage(`tnc.tnc_5_vi`) },
              { desc: locale.getMessage(`tnc.tnc_5_vii`) },
              { desc: locale.getMessage(`tnc.tnc_5_viii`) },
              { desc: locale.getMessage(`tnc.tnc_5_ix`) },
              { desc: locale.getMessage(`tnc.tnc_5_x`) },
              { desc: locale.getMessage(`tnc.tnc_5_xi`) },
              { desc: locale.getMessage(`tnc.tnc_5_xii`) },
              { desc: locale.getMessage(`tnc.tnc_5_xiii`) },
              { desc: locale.getMessage(`tnc.tnc_5_iv`) },
              { desc: locale.getMessage(`tnc.tnc_5_v`) },
              { desc: locale.getMessage(`tnc.tnc_5_vi`) },
            ],
          },
          { 
            title: locale.getMessage(`tnc.tnc_6_title`),
            content: [
              { desc: locale.getMessage(`tnc.tnc_6_a`) },
              { desc: locale.getMessage(`tnc.tnc_6_b`) },
              { desc: locale.getMessage(`tnc.tnc_6_c`) },
              { desc: locale.getMessage(`tnc.tnc_6_d`) },
              { desc: locale.getMessage(`tnc.tnc_6_e`) },
              { desc: locale.getMessage(`tnc.tnc_6_f`) },
              { desc: locale.getMessage(`tnc.tnc_6_g`) },
            ] 
          },
          { 
            title: locale.getMessage(`tnc.tnc_7_title`),
            content: [
              { desc: locale.getMessage(`tnc.tnc_7_a`) },
              { desc: locale.getMessage(`tnc.tnc_7_b`) },
              { desc: locale.getMessage(`tnc.tnc_7_c`) },
              { desc: locale.getMessage(`tnc.tnc_7_d`) },
              { desc: locale.getMessage(`tnc.tnc_7_e`) },
              { desc: locale.getMessage(`tnc.tnc_7_f`) },
              { 
                desc: locale.getMessage(`tnc.tnc_7_g`),
                content: [
                  { desc: locale.getMessage(`tnc.tnc_7_g_i`) },
                  { desc: locale.getMessage(`tnc.tnc_7_g_ii`) },
                  { desc: locale.getMessage(`tnc.tnc_7_g_iii`) },
                ]
              },
              { desc: locale.getMessage(`tnc.tnc_7_h`) },
              { desc: locale.getMessage(`tnc.tnc_7_i`) },
              { desc: locale.getMessage(`tnc.tnc_7_j`) },
              { desc: locale.getMessage(`tnc.tnc_7_k`) },
              { desc: locale.getMessage(`tnc.tnc_7_l`) },
              { desc: locale.getMessage(`tnc.tnc_7_m`) },
            ]
          },
          { 
            title: locale.getMessage(`tnc.tnc_8_title`),
            content: [
              { desc: locale.getMessage(`tnc.tnc_8_a`) },
              { desc: locale.getMessage(`tnc.tnc_8_b`) },
              { desc: locale.getMessage(`tnc.tnc_8_c`) },
              { 
                desc: locale.getMessage(`tnc.tnc_8_d`),
                content: [
                  { desc: locale.getMessage(`tnc.tnc_8_d_i`) },
                  { desc: locale.getMessage(`tnc.tnc_8_d_ii`) },
                  { desc: locale.getMessage(`tnc.tnc_8_d_iii`) },
                ]
              },
            ]
          },
          { 
            title: locale.getMessage(`tnc.tnc_9_title`),
            content: [
              { desc: locale.getMessage(`tnc.tnc_9_a`) },
              { desc: locale.getMessage(`tnc.tnc_9_b`) },
              { desc: locale.getMessage(`tnc.tnc_9_c`) },
              { desc: locale.getMessage(`tnc.tnc_9_d`) },
            ]
          },
          { 
            title: locale.getMessage(`tnc.tnc_10_title`),
            content: [
              { desc: locale.getMessage(`tnc.tnc_10_a`) },
              { desc: locale.getMessage(`tnc.tnc_10_b`) },
              { desc: locale.getMessage(`tnc.tnc_10_c`) },
              { desc: locale.getMessage(`tnc.tnc_10_d`) },
              { desc: locale.getMessage(`tnc.tnc_10_e`) },
            ]
          },
          { 
            title: locale.getMessage(`tnc.tnc_11_title`),
            content: [
              { desc: locale.getMessage(`tnc.tnc_11_a`) },
              { desc: locale.getMessage(`tnc.tnc_11_b`) },
              { desc: locale.getMessage(`tnc.tnc_11_c`) },
              { desc: locale.getMessage(`tnc.tnc_11_d`) },
              { desc: locale.getMessage(`tnc.tnc_11_e`) },
              { desc: locale.getMessage(`tnc.tnc_11_f`) },
              { desc: locale.getMessage(`tnc.tnc_11_g`) },
              { desc: locale.getMessage(`tnc.tnc_11_h`) },
              { desc: locale.getMessage(`tnc.tnc_11_i`) },
            ]
          },
          { 
            title: locale.getMessage(`tnc.tnc_12_title`),
            content: [
              { desc: locale.getMessage(`tnc.tnc_12_a`) },
              { 
                desc: locale.getMessage(`tnc.tnc_12_b`),
                content: [
                  { desc: locale.getMessage(`tnc.tnc_12_b_i`) },
                  { desc: locale.getMessage(`tnc.tnc_12_b_ii`) }
                ]
              },
              { 
                desc: locale.getMessage(`tnc.tnc_12_c`),
                content: [
                  { desc: locale.getMessage(`tnc.tnc_12_c_i`) },
                  { desc: locale.getMessage(`tnc.tnc_12_c_ii`) },
                  { desc: locale.getMessage(`tnc.tnc_12_c_iii`) },
                  { desc: locale.getMessage(`tnc.tnc_12_c_iv`) },
                ]
              },
              { 
                desc: locale.getMessage(`tnc.tnc_12_d`),
                content: [
                  { desc: locale.getMessage(`tnc.tnc_12_d_i`) },
                  { desc: locale.getMessage(`tnc.tnc_12_d_ii`) },
                  { desc: locale.getMessage(`tnc.tnc_12_d_iii`) },
                  { desc: locale.getMessage(`tnc.tnc_12_d_iv`) },
                ]
              },
            ]
          },
          { 
            title: locale.getMessage(`tnc.tnc_13_title`),
            content: [
              { desc: locale.getMessage(`tnc.tnc_13_a`) },
            ]
          },
          { 
            title: locale.getMessage(`tnc.tnc_14_title`),
            content: [
              { desc: locale.getMessage(`tnc.tnc_14_a`) },
              { desc: locale.getMessage(`tnc.tnc_14_b`) },
              { desc: locale.getMessage(`tnc.tnc_14_c`) },
              { desc: locale.getMessage(`tnc.tnc_14_d`) },
              { desc: locale.getMessage(`tnc.tnc_14_e`) },
              { desc: locale.getMessage(`tnc.tnc_14_f`) },
              { desc: locale.getMessage(`tnc.tnc_14_g`) },
              { desc: locale.getMessage(`tnc.tnc_14_h`) },
              { desc: locale.getMessage(`tnc.tnc_14_i`) },
            ]
          },
          { 
            title: locale.getMessage(`tnc.tnc_15_title`),
            content: [
              { 
                desc: locale.getMessage(`tnc.tnc_15_a`),
                content: [
                  { desc: locale.getMessage(`tnc.tnc_15_a_i`) },
                  { desc: locale.getMessage(`tnc.tnc_15_a_ii`) },
                  { desc: locale.getMessage(`tnc.tnc_15_a_iii`) },
                  { desc: locale.getMessage(`tnc.tnc_15_a_iv`) },
                  { desc: locale.getMessage(`tnc.tnc_15_a_v`) },
                  { desc: locale.getMessage(`tnc.tnc_15_a_vi`) },
                  { desc: locale.getMessage(`tnc.tnc_15_a_vii`) },
                  { desc: locale.getMessage(`tnc.tnc_15_a_viii`) },
                  { desc: locale.getMessage(`tnc.tnc_15_a_ix`) },
                  { desc: locale.getMessage(`tnc.tnc_15_a_x`) },
                  { desc: locale.getMessage(`tnc.tnc_15_a_xi`) },
                  { desc: locale.getMessage(`tnc.tnc_15_a_xii`) }
                ]
              },
              { desc: locale.getMessage(`tnc.tnc_15_b`) },
              { desc: locale.getMessage(`tnc.tnc_15_c`) }
            ]
          },
          { 
            title: locale.getMessage(`tnc.tnc_16_title`),
            content: [
              { desc: locale.getMessage(`tnc.tnc_16_a`) },
              { desc: locale.getMessage(`tnc.tnc_16_b`) },
              { desc: locale.getMessage(`tnc.tnc_16_c`) },
              { desc: locale.getMessage(`tnc.tnc_16_d`) },
            ]
          },
          { 
            title: locale.getMessage(`tnc.tnc_17_title`),
            content: [
              { desc: locale.getMessage(`tnc.tnc_17_a`) },
            ]
          },
          { 
            title: locale.getMessage(`tnc.tnc_18_title`),
            content: [
              { desc: locale.getMessage(`tnc.tnc_18_a`) },
              { 
                desc: locale.getMessage(`tnc.tnc_18_b`),
                content: [
                  { desc: locale.getMessage(`tnc.tnc_18_b_i`) },
                  { desc: locale.getMessage(`tnc.tnc_18_b_ii`) },
                  { desc: locale.getMessage(`tnc.tnc_18_b_iii`) }
                ]
              }
            ]
          },
          { 
            title: locale.getMessage(`tnc.tnc_19_title`),
            content: [
              { desc: locale.getMessage(`tnc.tnc_19_a`) },
              { desc: locale.getMessage(`tnc.tnc_19_b`) }
            ]
          },
        ]
      } 
    ]
  }),
  created() {
    this.metaTag()
  },
  methods: {
    metaTag() {
      const currentUrl = window.location.href;
      uiHelper.setCanonical(currentUrl)
      const domainWithProtocol = currentUrl.split('/')[2];
      const domain = domainWithProtocol.replace(/(^\w+:|^)\/\//, '');
      const path = window.location.pathname;
      const cleanPath = path.replace(/\/(en-BD|bn-BD)/i, '');
      const Links = `https://${domain}${cleanPath}`;
      const Linksen = `https://${domain}/en-BD${cleanPath}`;
      const Linksbn = `https://${domain}/bn-BD${cleanPath}`;
      uiHelper.setHreflangLinks(Links)
      uiHelper.setHreflangLinksen(Linksen)
      uiHelper.setHreflangLinksbd(Linksbn)
    },
  }
}
</script>
