<template>
  <div>
    <v-row justify="center" class="info-container-row">
      <v-col cols="11" md="2" class="info-side-panel">
        <app-info-side-menu></app-info-side-menu>
      </v-col>
      <v-col cols="11" md="7">
        <router-view></router-view>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {uiHelper, locale} from '@/util'
import AppInfoSideMenu from '@/components/info/infoSideMenu.vue'

export default {
  name: 'infoLayout',
  components: {
    AppInfoSideMenu
  },
  metaInfo: {
    title: `${locale.getMessage('meta.infoCenter_title')}`,
    titleTemplate: `%s`,
    meta: [
      {charset: 'utf-8'},
      {name: 'description', content: `${locale.getMessage('meta.infoCenter_description')}`},
      {name: 'keyword', content: `${locale.getMessage('meta.infoCenter_keyword')}`}
    ]
  }
}
</script>

<style lang="scss">
.info-page-card {
  min-height: 550px;
}

.info-expension-panel-content {
  background-color: white !important;
  color: black !important;
}

.info-expension-panel-content-title {
  text-decoration: underline;
}

.info-page-title {
  border-bottom: 4px solid var(--v-primary-base);
}

@media (max-width: 1500px) {
  .info-side-panel {
    min-width: 250px;
  }
}

@media (max-width: 1263px) {
  .info-page-title {
    font-size: 16px;
  }
}
</style>
